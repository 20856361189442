import { ActionType, createReducer } from 'typesafe-actions';
import { SettingsState } from './types';
import { settingsActions } from './actions';
import packageInfo from '../../../package.json';

const settingsDefaultState = {
  locations: [],
  autoAlertsStatus: {},
  digestStatus: {},
  loading: false,
  config: {
    defaultDateRange: '14',
    advancedAnalyticsDateRange: '7',
    mockedMerchantSequenceKey: '',
    name: '',
    isAppHubPartner: false,
    oneTrustLoaded: false
  },
  settings: {},
  pendingUpdate: false,
  merchantPrograms: {},
  merchantPages: {},
  versions: [],
  uiVersion: packageInfo.version,
  programs: [],
  isDemo: false,
  appHubTrayItems: {
    loading: false,
    data: []
  },
  accountName: '',
  partnerCountryCode: '',
  cesPlansDemoEnv: false,
  isCustomDemoData: false,
  partnerId: '',
  katanaKey: '',
  publicSitesDomain: '',
  chockStoneDomain: ''
};

export const settingsReducer = createReducer<SettingsState, ActionType<typeof settingsActions>>(settingsDefaultState)
  .handleAction(settingsActions.info.request, state => ({ ...state, loading: true }))
  .handleAction(settingsActions.info.failure, state => ({ ...state, loading: false }))
  .handleAction(settingsActions.info.success, (state, action) => {
    const {
      locations,
      autoAlertsStatus,
      digestStatus,
      config,
      settings,
      merchantPrograms,
      merchantPages,
      versions,
      programs,
      demoEnv: isDemo,
      partnerId,
      cesPlansDemoEnv,
      isCustomDemoData,
      userId,
      gpUserId,
      jwt,
      loyaltyPointsSetup,
      accountName,
      partnerCountryCode,
      katanaKey,
      publicSitesDomain,
      chockStoneDomain
    } = action.payload;

    return {
      ...state,
      userId,
      gpUserId,
      locations,
      autoAlertsStatus,
      digestStatus,
      settings,
      config,
      merchantPrograms,
      merchantPages,
      loading: false,
      versions,
      programs,
      jwt,
      isDemo,
      partnerId,
      cesPlansDemoEnv,
      isCustomDemoData,
      loyaltyPointsSetup,
      partnerCountryCode,
      accountName,
      katanaKey,
      publicSitesDomain,
      chockStoneDomain
    };
  })
  .handleAction(settingsActions.updateMyAccount.request, state => ({ ...state, pendingUpdate: true }))
  .handleAction(settingsActions.updateMyAccount.failure, state => ({ ...state, pendingUpdate: false }))
  .handleAction(settingsActions.updateMyAccount.success, (state, action) => {
    const { settingsResponse, digestResponse } = action.payload;
    return {
      ...state,
      settings: settingsResponse || state.settings,
      digestStatus: digestResponse || state.digestStatus,
      pendingUpdate: false,
      updatedAt: Date.now()
    };
  })
  .handleAction(settingsActions.updateNotifications.request, state => ({ ...state, pendingUpdate: true }))
  .handleAction(settingsActions.updateNotifications.failure, state => ({ ...state, pendingUpdate: false }))
  .handleAction(settingsActions.updateNotifications.success, (state, action) => ({
    ...state,
    pendingUpdate: false,
    digestStatus: { ...state.digestStatus, ...action.payload.digest },
    autoAlertsStatus: { ...state.autoAlertsStatus, ...action.payload.alerts },
    updatedAt: Date.now()
  }))
  .handleAction(settingsActions.getAppHubTrayItems.request, state => ({
    ...state,
    appHubTrayItems: { ...state.appHubTrayItems, loading: true }
  }))
  .handleAction(settingsActions.getAppHubTrayItems.failure, (state, action) => ({
    ...state,
    appHubTrayItems: { ...state.appHubTrayItems, loading: false, error: action.payload, data: [] }
  }))
  .handleAction(settingsActions.getAppHubTrayItems.success, (state, action) => ({
    ...state,
    appHubTrayItems: { ...state.appHubTrayItems, loading: false, error: undefined, data: action.payload }
  }))
  .handleAction(settingsActions.oneTrustStatus.success, (state, action) => ({
    ...state,
    oneTrustLoaded: action.payload
  }));
