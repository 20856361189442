import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { Page, PagesList } from 'store/settings/layout';
import { getColor } from 'layout/theme';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings-full.svg';
import { textSubtitleBold } from 'layout/mixins';
import { deviceMedia, headerHeight, toolBarHeight } from 'appConstants';
import { generateDataTestId } from 'services/utils';
import i18n from '../../../i18n';

const iconsInRow = 4;

const Title = styled.span`
  ${textSubtitleBold};
  @media ${deviceMedia.mobile} {
    font-size: 14px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  column-gap: 30px;
  row-gap: 35px;
  padding: 35px 15px 30px;
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(100vh - ${headerHeight + toolBarHeight + 50}px);
  background: ${props => getColor(props, 'lightGrey')};

  @media ${deviceMedia.tablet} {
    padding: 51px 24px 30px;
  }
`;

const DropdownItemContainer = styled.div`
  -webkit-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
  display: inline-flex;
  width: calc((100% - 90px) / 4);
  justify-content: center;
  align-items: center;
  text-align: center;
  &:nth-child(${iconsInRow}n+1) {
    margin-left: 0;
  }
  &:nth-child(${iconsInRow}n+${iconsInRow}) {
    margin-right: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  @media ${deviceMedia.tablet} {
    width: calc((99% - 60px) / 3);
    margin-left: 0;
    margin-right: 0;
  }
`;

const DropdownItemImage = styled.div`
  max-width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;

  div:first-of-type {
    height: 68px;
  }

  svg {
    width: 68px;
    height: 68px;
  }
`;

const ImagePlaceholderContainer = styled.div`
  display: inline-block;
  width: 69px;
  height: 69px;
  position: relative;
  &:before,
  &:after {
    background: ${props => getColor(props, 'color4')};
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1;
  }
  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    left: -1px;
    bottom: -1px;
    opacity: 0.3;
    z-index: 0;
  }
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 35px;
  height: 35px;
  z-index: 2;
  g[fill] {
    fill: ${props => getColor(props, 'white')};
  }
`;

const Link = ({ to, regularLink, children }: { to: string; regularLink: boolean; children: ReactNode }): JSX.Element =>
  regularLink || /^https?:\/\//.test(to) ? (
    <a href={to} target={regularLink ? undefined : '_blank'} rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <RouterLink to={to}>{children}</RouterLink>
  );

const ImagePlaceholder = (): JSX.Element => (
  <ImagePlaceholderContainer>
    <StyledSettingsIcon />
  </ImagePlaceholderContainer>
);

const DropdownItem = ({ item, onClick }: { item: Page; onClick: () => void }): JSX.Element => {
  const titleWithHyphens = i18n.t(`${item.translationKey}WithHyphens`, '') as string;
  return (
    <DropdownItemContainer onClick={onClick}>
      <Link to={item.path} regularLink={!!item.regularLink}>
        <DropdownItemImage>
          {item.topMenuIcon ? <div dangerouslySetInnerHTML={{ __html: item.topMenuIcon }} /> : <ImagePlaceholder />}
        </DropdownItemImage>
        {titleWithHyphens ? (
          <Title
            data-test-id={generateDataTestId(item.title, '4dot-icon')}
            dangerouslySetInnerHTML={{ __html: titleWithHyphens }}
          />
        ) : (
          <Title data-test-id={generateDataTestId(item.title, '4dot-icon')}>{item.title}</Title>
        )}
      </Link>
    </DropdownItemContainer>
  );
};

interface PropTypes {
  items: PagesList;
  notFoundEl: ReactNode;
  onItemClick: (page: Page) => void;
}

export const DropdownItems = ({ items, notFoundEl, onItemClick }: PropTypes): JSX.Element => (
  <Wrapper data-test-id="4dot-menu-items-container">
    {Array.isArray(items) && items.length
      ? items.map(page => <DropdownItem onClick={() => onItemClick(page)} item={page} key={page.pageId} />)
      : notFoundEl}
  </Wrapper>
);
